import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="combat"></a>
Combat
    </h2>
    <h3><a id="actions-in-combat"></a>
ACTIONS IN COMBAT
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <h5><a id="the-combat-round"></a>
THE COMBAT ROUND
    </h5>
    <p className="initial">
Each round represents 6 seconds in the game world. A round
presents an opportunity for each character involved in a combat
situation to take an action.
    </p>
    <p>
Each round�s activity begins with the character with the highest
initiative result and then proceeds, in order, from there. Each
round of a combat uses the same initiative order. When a
character�s turn comes up in the initiative sequence, that
character performs his entire round�s worth of actions. (For
exceptions, see Attacks of Opportunity and Special Initiative
Actions.)
    </p>
    <p>
For almost all purposes, there is no relevance to the end of a
round or the beginning of a round. A round can be a segment of
game time starting with the first character to act and ending with
the last, but it usually means a span of time from one round to
the same initiative count in the next round. Effects that last a
certain number of rounds end just before the same initiative count
that they began on.
    </p>
    <h5><a id="action-types"></a>
      <p>{`ACTION TYPES`}</p>
    </h5>
    <p className="initial">
An action�s type essentially tells you how long the action takes
to perform (within the framework of the 6-second combat round) and
how movement is treated. There are four types of actions: standard
actions, move actions, full-round actions, and free actions.
    </p>
    <p>
In a normal round, you can perform a standard action and a move
action, or you can perform a full-round action. You can also
perform one or more free actions. You can always take a move
action in place of a standard action.
    </p>
    <p>
In some situations (such as in a surprise round), you may be
limited to taking only a single move action or standard action.
    </p>
    <p><a id="standard-action"></a>
      <b>Standard Action</b>: A standard action allows you to do
something, most commonly make an attack or cast a spell. See
Table: Actions in Combat for other standard actions.
    </p>
    <p><a id="move-action"></a>
      <b>Move Action</b>: A move action allows you to move your speed or
perform an action that takes a similar amount of time. See Table:
Actions in Combat.
    </p>
    <p>
You can take a move action in place of a standard action. If you
move no actual distance in a round (commonly because you have
swapped your move for one or more equivalent actions), you can
take one 5-foot step either before, during, or after the action.
    </p>
    <p><a id="full-round-action"></a>
      <b>Full-Round Action</b>: A full-round action consumes all your
effort during a round. The only movement you can take during a
full-round action is a 5-foot step before, during, or after the
action. You can also perform free actions (see below).
    </p>
    <p>
Some full-round actions do not allow you to take a 5-foot step.
    </p>
    <p>
Some full-round actions can be taken as standard actions, but only
in situations when you are limited to performing only a standard
action during your round. The descriptions of specific actions,
below, detail which actions allow this option.
    </p>
    <p><a id="free-action"></a>
      <b>Free Action</b>: Free actions consume a very small amount of
time and effort. You can perform one or more free actions while
taking another action normally. However, there are reasonable
limits on what you can really do for free.
    </p>
    <p><a id="not-an-action"></a>
      <b>Not an Action</b>: Some activities are so minor that they are
not even considered free actions. They literally don�t take any
time at all to do and are considered an inherent part of doing
something else.
    </p>
    <p><a id="restricted-activity"></a>
      <b>Restricted Activity</b>: In some situations, you may be unable
to take a full round�s worth of actions. In such cases, you are
restricted to taking only a single standard action or a single
move action (plus free actions as normal). You can�t take a
full-round action (though you can start or complete a full-round
action by using a standard action; see below).
    </p>
    <p><a id="table-actions-in-combat"></a>
      <b>Table: Actions in Combat</b>
    </p>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="top">
          <td style={{
            "width": "35%"
          }}>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "75%"
                  }}>
Standard Action
                  </th>
                  <th className="center">
Attack of
                    <p>{`Opportunity`}<sup>{`1`}</sup></p>
                  </th>
                </tr>
                <tr className="odd-row">
                  <td>
Attack (melee)
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
Attack (ranged)
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
                    <p>{`Attack (unarmed)`}</p>
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr>
                  <td>
Activate a magic item other than a potion or oil
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Aid another
                  </td>
                  <td align="center">
Maybe<sup>2</sup>
                  </td>
                </tr>
                <tr>
                  <td>
Bull rush
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Cast a spell (1 standard action casting time)
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{`Concentrate to maintain an active spell`}</p>
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Dismiss a spell
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
Draw a hidden weapon (see Sleight of Hand skill)
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Drink a potion or apply an oil
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr>
                  <td>
Escape a grapple
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Feint
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
Light a torch with a tindertwig
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Lower spell resistance
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
Make a dying friend stable (see Heal skill)
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Overrun
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
Read a scroll
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Ready (triggers a standard action)
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
Sunder a weapon (attack)
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Sunder an object (attack)
                  </td>
                  <td align="center">
Maybe<sup>3</sup>
                  </td>
                </tr>
                <tr>
                  <td>
Total defense
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Turn or rebuke undead
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
Use extraordinary ability
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Use skill that takes 1 action
                  </td>
                  <td align="center">
Usually
                  </td>
                </tr>
                <tr>
                  <td>
Use spell-like ability
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Use supernatural ability
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
              </tbody>
            </table>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "75%"
                  }}>
Move Action
                  </th>
                  <th className="center">
Attack of
                    <p>{`Opportunity`}<sup>{`1`}</sup></p>
                  </th>
                </tr>
                <tr className="odd-row">
                  <td>
                    <p>{`Move`}</p>
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr>
                  <td>
Control a frightened mount
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Direct or redirect an active spell
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
Draw a weapon4
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Load a hand crossbow or light crossbow
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr>
                  <td>
Open or close a door
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Mount a horse or dismount
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
Move a heavy object
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Pick up an item
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr>
                  <td>
Sheathe a weapon
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Stand up from prone
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr>
                  <td>
Ready or loose a shield<sup>4</sup>
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Retrieve a stored item
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td style={{
            "width": "5%"
          }}>
          </td>
          <td style={{
            "width": "35%"
          }}>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "75%"
                  }}>
Full-Round Action
                  </th>
                  <th className="center">
Attack of
                    <p>{`Opportunity`}<sup>{`1`}</sup></p>
                  </th>
                </tr>
                <tr className="odd-row">
                  <td>
Full attack
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{`Charge`}<sup>{`5`}</sup></p>
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
                    <p>{`Deliver coup de grace`}</p>
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr>
                  <td>
Escape from a net
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Extinguish flames
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
Light a torch
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Load a heavy or repeating crossbow
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr>
                  <td>
Lock or unlock weapon in locked gauntlet
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Prepare to throw splash weapon
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr>
                  <td>
Run
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Use skill that takes 1 round
                  </td>
                  <td align="center">
Usually
                  </td>
                </tr>
                <tr>
                  <td>
Use touch spell on up to six friends
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Withdraw<sup>5</sup>
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
              </tbody>
            </table>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "75%"
                  }}>
Free Action
                  </th>
                  <th className="center">
Attack of
                    <p>{`Opportunity`}<sup>{`1`}</sup></p>
                  </th>
                </tr>
                <tr className="odd-row">
                  <td>
Cast a quickened spell
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{`Cease concentration on a spell`}</p>
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Drop an item
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td>
Drop to the floor
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
Prepare spell components to cast a spell<sup>6</sup>
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td className="last-row">
Speak
                  </td>
                  <td className="last-row" align="center">
No
                  </td>
                </tr>
              </tbody>
            </table>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "75%"
                  }}>
No Action
                  </th>
                  <th className="center">
Attack of
                    <p>{`Opportunity`}<sup>{`1`}</sup></p>
                  </th>
                </tr>
                <tr className="odd-row">
                  <td>
Delay
                  </td>
                  <td align="center">
                    <p>{`No`}</p>
                  </td>
                </tr>
                <tr>
                  <td className="last-row">
5-foot step
                  </td>
                  <td className="last-row" align="center">
No
                  </td>
                </tr>
              </tbody>
            </table>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "100%"
            }}>
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "75%"
                  }}>
Action Type Varies
                  </th>
                  <th className="center">
Attack of
                    <p>{`Opportunity`}<sup>{`1`}</sup></p>
                  </th>
                </tr>
                <tr className="odd-row">
                  <td>
                    <p>{`Disarm`}<sup>{`7`}</sup></p>
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{`Grapple`}<sup>{`7`}</sup></p>
                  </td>
                  <td align="center">
Yes
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>
                    <p>{`Trip an opponent`}<sup>{`7`}</sup></p>
                  </td>
                  <td align="center">
No
                  </td>
                </tr>
                <tr>
                  <td className="last-row">
                    <p>{`Use feat`}<sup>{`8`}</sup></p>
                  </td>
                  <td className="last-row" align="center">
Varies
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td style={{
            "width": "25%"
          }}>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
1 <i>Regardless of the action, if you move out of a
threatened square, you usually provoke an attack of
opportunity. This column indicates whether the action
itself, not moving, provokes an attack of opportunity.</i>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
2 <i>If you aid someone performing an action that would
normally provoke an attack of opportunity, then the act of
aiding another provokes an attack of opportunity as
well.</i>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
3 <i>If the object is being held, carried, or worn by a
creature, yes. If not, no.</i>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
4 <i>If you have a base attack bonus of +1 or higher, you
can combine one of these actions with a regular move. If you
have the Two- Weapon Fighting feat, you can draw two light
or one-handed weapons in the time it would normally take you
to draw one.</i>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
5 <i>May be taken as a standard action if you are limited to
taking only a single action in a round.</i>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
6 <i>Unless the component is an extremely large or awkward
item.</i>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
7 <i>These attack forms substitute for a melee attack, not
an action. As melee attacks, they can be used once in an
attack or charge action, one or more times in a full attack
action, or even as an attack of opportunity.</i>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
8 <i>The description of a feat defines its effect.</i>
          </td>
        </tr>
      </tbody>
    </table>
    <h5><a id="standard-actions"></a>
STANDARD ACTIONS
    </h5>
    <h6><a id="attack"></a>
Attack
    </h6>
    <p className="initial">
Making an attack is a standard action.
    </p>
    <p><a id="melee-attacks"></a>
      <b>Melee Attacks</b>: With a normal melee weapon, you can strike
any opponent within 5 feet. (Opponents within 5 feet are
considered adjacent to you.) Some melee weapons have reach, as
indicated in their descriptions. With a typical reach weapon, you
can strike opponents 10 feet away, but you can�t strike adjacent
foes (those within 5 feet).
    </p>
    <p><a id="unarmed-attacks"></a>
      <b>Unarmed Attacks</b>: Striking for damage with punches, kicks,
and head butts is much like attacking with a melee weapon, except
for the following:
    </p>
    <p><a id="unarmed-attacks-and-attacks-of-opportunity"></a>
      <i>Attacks of Opportunity</i>: Attacking unarmed provokes an
attack of opportunity from the character you attack, provided she
is armed. The attack of opportunity comes before your attack. An
unarmed attack does not provoke attacks of opportunity from other
foes nor does it provoke an attack of opportunity from an unarmed
foe.
    </p>
    <p>
An unarmed character can�t take attacks of opportunity (but see
&#8220;Armed&#8221; Unarmed Attacks, below).
    </p>
    <p>
      <i>&#8220;Armed&#8221; Unarmed Attacks</i>: Sometimes a character�s or
creature�s unarmed attack counts as an armed attack. A monk, a
character with the Improved Unarmed Strike feat, a spellcaster
delivering a touch attack spell, and a creature with natural
physical weapons all count as being armed. Note that being armed
counts for both offense and defense (the character can make
attacks of opportunity)
    </p>
    <p><a id="unarmed-strike-damage"></a>
      <i>Unarmed Strike Damage</i>: An unarmed strike from a Medium
character deals 1d3 points of damage (plus your Strength modifier,
as normal). A Small character�s unarmed strike deals 1d2 points of
damage, while a Large character�s unarmed strike deals 1d4 points
of damage. All damage from unarmed strikes is nonlethal damage.
Unarmed strikes count as light weapons (for purposes of two-weapon
attack penalties and so on).
    </p>
    <p><a id="dealing-lethal-damage"></a>
      <i>Dealing Lethal Damage</i>: You can specify that your unarmed
strike will deal lethal damage before you make your attack roll,
but you take a �4 penalty on your attack roll. If you have the
Improved Unarmed Strike feat, you can deal lethal damage with an
unarmed strike without taking a penalty on the attack roll.
    </p>
    <p><a id="ranged-attacks"></a>
      <b>Ranged Attacks</b>: With a ranged weapon, you can shoot or
throw at any target that is within the weapon�s maximum range and
in line of sight. The maximum range for a thrown weapon is five
range increments. For projectile weapons, it is ten range
increments. Some ranged weapons have shorter maximum ranges, as
specified in their descriptions.
    </p>
    <p><a id="attack-rolls"></a>
      <b>Attack Rolls</b>: An attack roll represents your attempts to
strike your opponent.
    </p>
    <p>
Your attack roll is 1d20 + your attack bonus with the weapon
you�re using. If the result is at least as high as the target�s
AC, you hit and deal damage.
    </p>
    <p><a id="automatic-misses-and-hits"></a>
      <b>Automatic Misses and Hits</b>: A natural 1 (the d20 comes up 1)
on the attack roll is always a miss. A natural 20 (the d20 comes
up 20) is always a hit. A natural 20 is also a threat�a possible
critical hit.
    </p>
    <p><a id="damage-rolls"></a>
      <b>Damage Rolls</b>: If the attack roll result equals or exceeds
the target�s AC, the attack hits and you deal damage. Roll the
appropriate damage for your weapon. Damage is deducted from the
target�s current hit points.
    </p>
    <p><a id="multiple-attacks"></a>
      <b>Multiple Attacks</b>: A character who can make more than one
attack per round must use the full attack action (see Full-Round
Actions, below) in order to get more than one attack.
    </p>
    <p><a id="shooting-or-throwing-into-melee"></a>
      <b>Shooting or Throwing into a Melee</b>: If you shoot or throw a
ranged weapon at a target engaged in melee with a friendly
character, you take a �4 penalty on your attack roll. Two
characters are engaged in melee if they are enemies of each other
and either threatens the other. (An unconscious or otherwise
immobilized character is not considered engaged unless he is
actually being attacked.)
    </p>
    <p>
If your target (or the part of your target you�re aiming at, if
it�s a big target) is at least 10 feet away from the nearest
friendly character, you can avoid the �4 penalty, even if the
creature you�re aiming at is engaged in melee with a friendly
character.
    </p>
    <p><a id="precise-shot"></a>
      <i>Precise Shot</i>: If you have the Precise Shot feat you don�t
take this penalty.
    </p>
    <p><a id="fighting-defensively-as-a-standard-action"></a>
      <a id="fight-defensively"></a>
      <b>Fighting Defensively as a Standard Action</b>: You can choose
to fight defensively when attacking. If you do so, you take a �4
penalty on all attacks in a round to gain a +2 dodge bonus to AC
for the same round.
    </p>
    <p><a id="critical-hits"></a>
      <b>Critical Hits</b>: When you make an attack roll and get a
natural 20 (the d20 shows 20), you hit regardless of your target�s
Armor Class, and you have scored a threat. The hit might be a
critical hit (or �crit�). To find out if it�s a critical hit, you
immediately make a critical roll � another attack roll with all the
same modifiers as the attack roll you just made. If the critical
roll also results in a hit against the target�s AC, your original
hit is a critical hit. (The critical roll just needs to hit to
give you a crit. It doesn�t need to come up 20 again.) If the
critical roll is a miss, then your hit is just a regular hit.
    </p>
    <p>
A critical hit means that you roll your damage more than once,
with all your usual bonuses, and add the rolls together. Unless
otherwise specified, the threat range for a critical hit on an
attack roll is 20, and the multiplier is x2.
    </p>
    <p><a id="critical-hit-exception"></a>
      <i>Exception</i>: Extra damage over and above a weapon�s normal
damage is not multiplied when you score a critical hit.
    </p>
    <p><a id="increased-threat-range"></a>
      <i>Increased Threat Range</i>: Sometimes your threat range is
greater than 20. That is, you can score a threat on a lower
number. In such cases, a roll of lower than 20 is not an automatic
hit. Any attack roll that doesn�t result in a hit is not a threat.
    </p>
    <p><a id="increased-critical-multiplier"></a>
      <i>Increased Critical Multiplier</i>: Some weapons deal better
than double damage on a critical hit.
    </p>
    <p><a id="spells-and-critical-hits"></a>
      <i>Spells and Critical Hits</i>: A spell that requires an attack
roll can score a critical hit. A spell attack that requires no
attack roll cannot score a critical hit.
    </p>
    <h6>
      <a id="cast-a-standard-action-spell"></a>Cast a Spell
    </h6>
    <p className="initial">
Most spells require 1 standard action to cast. You can cast such a
spell either before or after you take a move action.
    </p>
    <p>
      <i>Note</i>: You retain your Dexterity bonus to AC while casting.
    </p>
    <p><a id="spell-components"></a>
      <b>Spell Components</b>: To cast a spell with a verbal (V)
component, your character must speak in a firm voice. If you�re
gagged or in the area of a silence spell, you can�t cast such a
spell. A spellcaster who has been deafened has a 20% chance to
spoil any spell he tries to cast if that spell has a verbal
component.
    </p>
    <p>
To cast a spell with a somatic (S) component, you must gesture
freely with at least one hand. You can�t cast a spell of this type
while bound, grappling, or with both your hands full or occupied.
    </p>
    <p>
To cast a spell with a material (M), focus (F), or divine focus
(DF) component, you have to have the proper materials, as
described by the spell. Unless these materials are elaborate
preparing these materials is a free action. For material
components and focuses whose costs are not listed, you can assume
that you have them if you have your spell component pouch.
    </p>
Some spells have an experience point (XP) component and entail
an experience point cost to you. No spell can restore the lost XP.
You cannot spend so much XP that you lose a level, so you cannot
cast the spell unless you have enough XP to spare. However, you may,
on gaining enough XP to achieve a new level, immediately spend the
XP on casting the spell rather than keeping it to advance a level.
The XP are expended when you cast the spell, whether or not the
casting succeeds.
    <p><a id="concentration"></a>
      <b>Concentration</b>: You must concentrate to cast a spell. If you
can�t concentrate you can�t cast a spell. If you start casting a
spell but something interferes with your concentration you must
make a Concentration check or lose the spell. The check�s DC
depends on what is threatening your concentration (see the
Concentration skill). If you fail, the spell fizzles with no
effect. If you prepare spells, it is lost from preparation. If you
cast at will, it counts against your daily limit of spells even
though you did not cast it successfully.
    </p>
    <p>
      <b>Concentrating to Maintain a Spell</b>: Some spells require
continued concentration to keep them going. Concentrating to
maintain a spell is a standard action that doesn�t provoke an
attack of opportunity. Anything that could break your
concentration when casting a spell can keep you from concentrating
to maintain a spell. If your concentration breaks, the spell ends.
    </p>
    <p><a id="casting-time"></a>
      <b>Casting Time</b>: Most spells have a casting time of 1 standard
action. A spell cast in this manner immediately takes effect.
    </p>
    <p><a id="casting-a-spell-and-attacks-of-opportunity"></a>
      <b>Attacks of Opportunity</b>: Generally, if you cast a spell, you
provoke attacks of opportunity from threatening enemies. If you
take damage from an attack of opportunity, you must make a
Concentration check (DC 10 + points of damage taken + spell level)
or lose the spell. Spells that require only a free action to cast
don�t provoke attacks of opportunity.
    </p>
    <p><a id="casting-on-the-defensive"></a>
      <b>Casting on the Defensive</b>: Casting a spell while on the
defensive does not provoke an attack of opportunity. It does,
however, require a Concentration check (DC 15 + spell level) to
pull off. Failure means that you lose the spell.
    </p>
    <p><a id="touch-spells-in-combat"></a>
      <b>Touch Spells in Combat</b>: Many spells have a range of touch.
To use these spells, you cast the spell and then touch the
subject, either in the same round or any time later. In the same
round that you cast the spell, you may also touch (or attempt to
touch) the target. You may take your move before casting the
spell, after touching the target, or between casting the spell and
touching the target. You can automatically touch one friend or use
the spell on yourself, but to touch an opponent, you must succeed
on an attack roll.
    </p>
    <p><a id="touch-attacks"></a>
      <i>Touch Attacks</i>: Touching an opponent with a touch spell is
considered to be an armed attack and therefore does not provoke
attacks of opportunity. However, the act of casting a spell does
provoke an attack of opportunity. Touch attacks come in two types:
melee touch attacks and ranged touch attacks. You can score
critical hits with either type of attack. Your opponent�s AC
against a touch attack does not include any armor bonus, shield
bonus, or natural armor bonus. His size modifier, Dexterity
modifier, and deflection bonus (if any) all apply normally.
    </p>
    <p><a id="holding-the-charge"></a>
      <i>Holding the Charge</i>: If you don�t discharge the spell in the
round when you cast the spell, you can hold the discharge of the
spell (hold the charge) indefinitely. You can continue to make
touch attacks round after round. You can touch one friend as a
standard action or up to six friends as a full-round action. If
you touch anything or anyone while holding a charge, even
unintentionally, the spell discharges. If you cast another spell,
the touch spell dissipates. Alternatively, you may make a normal
unarmed attack (or an attack with a natural weapon) while holding
a charge. In this case, you aren�t considered armed and you
provoke attacks of opportunity as normal for the attack. (If your
unarmed attack or natural weapon attack doesn�t provoke attacks of
opportunity, neither does this attack.) If the attack hits, you
deal normal damage for your unarmed attack or natural weapon and
the spell discharges. If the attack misses, you are still holding
the charge.
    </p>
    <p><a id="dismiss-a-spell"></a><a id="dismiss"></a>
      <i>Dismiss a Spell</i>: Dismissing an active spell is a standard
action that doesn�t provoke attacks of opportunity.
    </p>
    <h6><a id="activate-magic-item"></a>
Activate Magic Item
    </h6>
    <p className="initial">
Many magic items don�t need to be activated. However, certain
magic items need to be activated, especially potions, scrolls,
wands, rods, and staffs. Activating a magic item is a standard
action (unless the item description indicates otherwise).
    </p>
    <p><a id="spell-completion-items"></a>
      <b>Spell Completion Items</b>: Activating a spell completion item
is the equivalent of casting a spell. It requires concentration
and provokes attacks of opportunity. You lose the spell if your
concentration is broken, and you can attempt to activate the item
while on the defensive, as with casting a spell.
    </p>
    <p><a id="spell-trigger-command-word-or-use-activated-items"></a>
      <b>Spell Trigger, Command Word, or Use-Activated Items</b>:
Activating any of these kinds of items does not require
concentration and does not provoke attacks of opportunity.
    </p>
    <h6><a id="use-special-ability"></a>
Use Special Ability
    </h6>
    <p className="initial">
Using a special ability is usually a standard action, but whether
it is a standard action, a full-round action, or not an action at
all is defined by the ability.
    </p>
    <p><a id="using-spell-like-abilities"></a>
      <b>Spell-Like Abilities</b>: Using a spell-like ability works like
casting a spell in that it requires concentration and provokes
attacks of opportunity. Spell-like abilities can be disrupted. If
your concentration is broken, the attempt to use the ability
fails, but the attempt counts as if you had used the ability. The
casting time of a spell-like ability is 1 standard action, unless
the ability description notes otherwise.
    </p>
    <p><a id="using-a-spell-like-ability-on-the-defensive"></a>
      <i>Using a Spell-Like Ability on the Defensive</i>: You may
attempt to use a spell-like ability on the defensive, just as with
casting a spell. If the Concentration check (DC 15 + spell level)
fails, you cant use the ability, but the attempt counts as if you
had used the ability.
    </p><a id="using-supernatural-abilities"></a>
    <p>
      <b>Supernatural Abilities</b>: Using a supernatural ability is
usually a standard action (unless defined otherwise by the
ability�s description). Its use cannot be disrupted, does not
require concentration, and does not provoke attacks of
opportunity.
    </p>
    <p><a id="using-extraordinary-abilities"></a>
      <b>Extraordinary Abilities</b>: Using an extraordinary ability is
usually not an action because most extraordinary abilities
automatically happen in a reactive fashion. Those extraordinary
abilities that are actions are usually standard actions that
cannot be disrupted, do not require concentration, and do not
provoke attacks of opportunity.
    </p>
    <h6><a id="total-defense"></a>
Total Defense
    </h6>
    <p className="initial">
You can defend yourself as a standard action. You get a +4 dodge
bonus to your AC for 1 round. Your AC improves at the start of
this action. You can�t combine total defense with fighting
defensively or with the benefit of the <a href="featsAll.html#combat-expertice">Combat Expertise</a> feat
(since both of those require you to declare an attack or full
attack). You can�t make attacks of opportunity while using total
defense.
    </p>
    <h6><a id="start-complete-full-round-action"></a>
Start/Complete Full-Round Action
    </h6>
    <p className="initial">
      <p>{`The �start full-round action� standard action lets you start
undertaking a full-round action, which you can complete in the
following round by using another standard action. You can�t use
this action to start or complete a full attack, charge, run, or
withdraw.`}</p>
    </p>
    <h5><a id="move-actions"></a>
MOVE ACTIONS
    </h5>
    <p className="initial">
With the exception of specific movement-related skills, most move
actions don�t require a check.
    </p>
    <h6>
      <a id="move"></a>Move
    </h6>
    <p className="initial">
The simplest move action is moving your speed. If you take this
kind of move action during your turn, you can�t also take a 5-foot
step.
    </p>
    <p>
Many nonstandard modes of movement are covered under this
category, including climbing (up to one-quarter of your speed) and
swimming (up to one-quarter of your speed).
    </p>
    <p><a id="accelerated-climbing"></a>
      <b>Accelerated Climbing</b>: You can climb one-half your speed as
a move action by accepting a �5 penalty on your Climb check.
    </p>
    <p><a id="crawling"></a>
      <b>Crawling</b>: You can crawl 5 feet as a move action. Crawling
incurs attacks of opportunity from any attackers who threaten you
at any point of your crawl.
    </p>
    <h6><a id="draw-or-sheathe-a-weapon"></a>
      <a id="draw-or-sheathe"></a>Draw or Sheathe a Weapon
    </h6>
    <p className="initial">
Drawing a weapon so that you can use it in combat, or putting it
away so that you have a free hand, requires a move action. This
action also applies to weapon-like objects carried in easy reach,
such as wands. If your weapon or weapon-like object is stored in a
pack or otherwise out of easy reach, treat this action as
retrieving a stored item.
    </p>
    <p>
If you have a base attack bonus of +1 or higher, you may draw a
weapon as a free action combined with a regular move. If you have
the Two-Weapon Fighting feat, you can draw two light or one-handed
weapons in the time it would normally take you to draw one.
    </p>
    <p>
Drawing ammunition for use with a ranged weapon (such as arrows,
bolts, sling bullets, or shuriken) is a free action.
    </p>
    <h6><a id="ready-or-loose-a-shield"></a>
      <a id="ready-or-loose"></a>Ready or Loose a Shield
    </h6>
    <p className="initial">
Strapping a shield to your arm to gain its shield bonus to your
AC, or unstrapping and dropping a shield so you can use your
shield hand for another purpose, requires a move action. If you
have a base attack bonus of +1 or higher, you can ready or loose a
shield as a free action combined with a regular move.
    </p>
    <p>
      <p>{`Dropping a carried (but not worn) shield is a free action.`}</p>
    </p>
    <h6><a id="manipulate-an-item"></a>
Manipulate an Item
    </h6>
    <p className="initial">
In most cases, moving or manipulating an item is a move action.
This includes retrieving or putting away a stored item, picking up
an item, moving a heavy object, and opening a door. Examples of
this kind of action, along with whether they incur an attack of
opportunity, are given in Table: Actions in Combat.
    </p>
    <h6>
      <a id="direct-or-redirect-a-spell"></a>Direct or Redirect a
Spell
    </h6>
    <p className="initial">
Some spells allow you to redirect the effect to new targets or
areas after you cast the spell. Redirecting a spell requires a
move action and does not provoke attacks of opportunity or require
concentration.
    </p>
    <h6>
      <a id="stand-up"></a>Stand Up
    </h6>
    <p className="initial">
Standing up from a prone position requires a move action and
provokes attacks of opportunity.
    </p>
    <h6>
      <a id="mount-dismount"></a>Mount/Dismount a Steed
    </h6>
    <p className="initial">
Mounting or dismounting from a steed requires a move action.
    </p>
    <p><a id="fast-mount-or-dismount"></a>
      <b>Fast Mount or Dismount</b>: You can mount or dismount as a free
action with a DC 20 Ride check (your armor check penalty, if any,
applies to this check). If you fail the check, mounting or
dismounting is a move action instead. (You can�t attempt a fast
mount or fast dismount unless you can perform the mount or
dismount as a move action in the current round.)
    </p>
    <h5><a id="full-round-actions"></a>
FULL-ROUND ACTIONS
    </h5>
    <p className="initial">
A full-round action requires an entire round to complete. Thus, it
can�t be coupled with a standard or a move action, though if it
does not involve moving any distance, you can take a 5-foot step.
    </p>
    <h6>
      <a id="full-attack"></a>Full Attack
    </h6>
    <p className="initial">
If you get more than one attack per round because your base attack
bonus is high enough, because you fight with two weapons or a
double weapon or for some special reason you must use a full-round
action to get your additional attacks. You do not need to specify
the targets of your attacks ahead of time. You can see how the
earlier attacks turn out before assigning the later ones.
    </p>
    <p>
      <p>{`The only movement you can take during a full attack is a 5-foot
step. You may take the step before, after, or between your
attacks.`}</p>
    </p>
    <p>
If you get multiple attacks because your base attack bonus is high
enough, you must make the attacks in order from highest bonus to
lowest. If you are using two weapons, you can strike with either
weapon first. If you are using a double weapon, you can strike
with either part of the weapon first.
    </p>
    <p><a id="deciding-between-an-attack-or-a-full-attack"></a>
      <b>Deciding between an Attack or a Full Attack</b>: After your
first attack, you can decide to take a move action instead of
making your remaining attacks, depending on how the first attack
turns out. If you�ve already taken a 5-foot step, you can�t use
your move action to move any distance, but you could still use a
different kind of move action.
    </p>
    <p><a id="fighting-defensively-as-a-full-round-action"></a>
      <b>Fighting Defensively as a Full-Round Action</b>: You can choose
to fight defensively when taking a full attack action. If you do
so, you take a �4 penalty on all attacks in a round to gain a +2
dodge bonus to AC for the same round.
    </p>
    <p><a id="cleave"></a>
      <b>Cleave</b>: The extra attack granted by the Cleave feat or
Great Cleave feat can be taken whenever they apply. This is an
exception to the normal limit to the number of attacks you can
take when not using a full attack action.
    </p>
    <h6><a id="cast-a-full-round-action-spell"></a>
Cast a Spell
    </h6>
    <p className="initial">
A spell that takes 1 round to cast is a full-round action. It
comes into effect just before the beginning of your turn in the
round after you began casting the spell. You then act normally
after the spell is completed.
    </p>
    <p>
      <p>{`A spell that takes 1 minute to cast comes into effect just before
your turn 1 minute later (and for each of those 10 rounds, you are
casting a spell as a full-round action). These actions must be
consecutive and uninterrupted, or the spell automatically fails.`}</p>
    </p>
    <p>
When you begin a spell that takes 1 round or longer to cast, you
must continue the invocations, gestures, and concentration from
one round to just before your turn in the next round (at least).
If you lose concentration after starting the spell and before it
is complete, you lose the spell.
    </p>
    <p>
You only provoke attacks of opportunity when you begin casting a
spell, even though you might continue casting for at least one
full round. While casting a spell, you don�t threaten any squares
around you.
    </p>
    <p>
This action is otherwise identical to the cast a spell action
described under Standard Actions.
    </p>
    <p><a id="casting-a-metamagic-spell"></a>
      <b>Casting a Metamagic Spell</b>: Sorcerers and bards must take
more time to cast a metamagic spell (one enhanced by a metamagic
feat) than a regular spell. If a spell�s normal casting time is 1
standard action, casting a metamagic version of the spell is a
full-round action for a sorcerer or bard. Note that this isn�t the
same as a spell with a 1-round casting time � the spell takes effect
in the same round that you begin casting, and you aren�t required
to continue the invocations, gestures, and concentration until
your next turn. For spells with a longer casting time, it takes an
extra full-round action to cast the metamagic spell.
    </p>
    <p>
Clerics must take more time to spontaneously cast a metamagic
version of a cure or inflict spell. Spontaneously casting a
metamagic version of a spell with a casting time of 1 standard
action is a full-round action, and spells with longer casting
times take an extra full-round action to cast.
    </p>
    <h6><a id="use-full-round-special-ability"></a>
Use Special Ability
    </h6>
    <p className="initial">
      <p>{`Using a special ability is usually a standard action, but some may
be full-round actions, as defined by the ability.`}</p>
    </p>
    <h6>
      <a id="withdraw">Withdraw</a>
    </h6>
    <p className="initial">
Withdrawing from melee combat is a full-round action. When you
withdraw, you can move up to double your speed. The square you
start out in is not considered threatened by any opponent you can
see, and therefore visible enemies do not get attacks of
opportunity against you when you move from that square. (Invisible
enemies still get attacks of opportunity against you, and you
can�t withdraw from combat if you�re blinded.) You can�t take a
5-foot step during the same round in which you withdraw.
    </p>
    <p>
      <p>{`If, during the process of withdrawing, you move out of a
threatened square (other than the one you started in), enemies get
attacks of opportunity as normal.`}</p>
    </p>
    <p>
You may not withdraw using a form of movement for which you don�t
have a listed speed.
    </p>
    <p>
Note that despite the name of this action, you don�t actually have
to leave combat entirely.
    </p>
    <p><a id="restricted-withdraw"></a>
      <b>Restricted Withdraw</b>: If you are limited to taking only a
standard action each round you can withdraw as a standard action.
In this case, you may move up to your speed (rather than up to
double your speed).
    </p>
    <h6>
      <a id="run"></a>Run
    </h6>
    <p className="initial">
You can run as a full-round action. (If you do, you do not also
get a 5-foot step.) When you run, you can move up to four times
your speed in a straight line (or three times your speed if you�re
in heavy armor). You lose any Dexterity bonus to AC unless you
have the Run feat
    </p>
    <p>
You can run for a number of rounds equal to your Constitution
score, but after that you must make a DC 10 Constitution check to
continue running. You must check again each round in which you
continue to run, and the DC of this check increases by 1 for each
check you have made. When you fail this check, you must stop
running. A character who has run to his limit must rest for 1
minute (10 rounds) before running again. During a rest period, a
character can move no faster than a normal move action.
    </p>
    <p>
      <p>{`You can�t run across difficult terrain or if you can�t see where
you�re going.`}</p>
    </p>
    <p>
A run represents a speed of about 12 miles per hour for an
unencumbered human.
    </p>
    <h6><a id="move-5-feet-through-difficult-terrain"></a>
Move 5 Feet through Difficult Terrain
    </h6>
    <p className="initial">
In some situations, your movement may be so hampered that you
don�t have sufficient speed even to move 5 feet (a single square).
In such a case, you may spend a full-round action to move 5 feet
(1 square) in any direction, even diagonally. Even though this
looks like a 5-foot step, it�s not, and thus it provokes attacks
of opportunity normally.
    </p>
    <h5><a id="free-actions"></a>
FREE ACTIONS
    </h5>
    <p className="initial">
Free actions don�t take any time at all, though there may be
limits to the number of free actions you can perform in a turn.
Free actions rarely incur attacks of opportunity. Some common free
actions are described below.
    </p>
    <h6>
      <a id="drop-an-item"></a>Drop an Item
    </h6>
    <p className="initial">
      <p>{`Dropping an item in your space or into an adjacent square is a
free action.`}</p>
    </p>
    <h6>
      <a id="drop-prone"></a>Drop Prone
    </h6>
    <p className="initial">
Dropping to a prone position in your space is a free action.
    </p>
    <h6>
      <a id="speak"></a>Speak
    </h6>
    <p className="initial">
In general, speaking is a free action that you can perform even
when it isn�t your turn. Speaking more than few sentences is
generally beyond the limit of a free action.
    </p>
    <h6><a id="cease-concentration-on-a-spell"></a>
Cease Concentration on Spell
    </h6>
    <p className="initial">
You can stop concentrating on an active spell as a free action.
    </p>
    <h6><a id="cast-a-quickened-spell"></a>
      <p>{`Cast a Quickened Spell`}</p>
    </h6>
    <p className="initial">
You can cast a quickened spell (see the Quicken Spell feat) or any
spell whose casting time is designated as a free action as a free
action. Only one such spell can be cast in any round, and such
spells don�t count toward your normal limit of one spell per
round. Casting a spell with a casting time of a free action
doesn�t incur an attack of opportunity.
    </p>
    <h5><a id="miscellaneous-actions"></a>
MISCELLANEOUS ACTIONS
    </h5>
    <h6>
      <a id="step-5-ft"></a>Take 5-Foot Step
    </h6>
    <p className="initial">
You can move 5 feet in any round when you don�t perform any other
kind of movement. Taking this 5-foot step never provokes an attack
of opportunity. You can�t take more than one 5-foot step in a
round, and you can�t take a 5-foot step in the same round when you
move any distance.
    </p>
    <p>
You can take a 5-foot step before, during, or after your other
actions in the round.
    </p>
    <p>
You can only take a 5-foot-step if your movement isn�t hampered by
difficult terrain or darkness. Any creature with a speed of 5 feet
or less can�t take a 5-foot step, since moving even 5 feet
requires a move action for such a slow creature.
    </p>
    <p>
      <p>{`You may not take a 5-foot step using a form of movement for which
you do not have a listed speed.`}</p>
    </p>
    <h6><a id="use-feat"></a>
Use Feat
    </h6>
    <p className="initial">
Certain feats let you take special actions in combat. Other feats
do not require actions themselves, but they give you a bonus when
attempting something you can already do. Some feats are not meant
to be used within the framework of combat. The individual feat
descriptions tell you what you need to know about them.
    </p>
    <h6><a id="use-skill"></a>
Use Skill
    </h6>
    <p className="initial">
Most skill uses are standard actions, but some might be move
actions, full-round actions, free actions, or something else
entirely. The individual skill descriptions tell you what sorts of
actions are required to perform skills.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      